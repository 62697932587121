import React from 'react';
import { CardHeader, Typography } from "@material-tailwind/react";
import { ReactNode } from "react";

interface TableHeaderProps {
    title: string,
    description: string,
    sideComponent: ReactNode
}
export default function TableHeader(props: TableHeaderProps) {
    const {
        title,
        description,
        sideComponent
    } = props
    return <CardHeader floated={false} shadow={false} className="rounded-none overflow-visible">
        <div className="mb-4 flex flex-row justify-between gap-8 md:flex-row md:items-center">
            <div>
                <Typography variant="h5" color="blue-gray" className='capitalize'>
                    {title}
                </Typography>
                <Typography color="gray" className="mt-1 font-normal">
                    {description}
                </Typography>
            </div>
            <div className="flex w-full shrink-0 gap-2 w-max">
                {sideComponent}
            </div>
        </div>
    </CardHeader>
}