import React, { useState } from "react";
import {
    Navbar,
    Typography,
    IconButton,
    Drawer,
} from "@material-tailwind/react";
import VennLogo from '@assets/images/UI/Venn.png';
import './NavBar.css';

export function StickyNavBar() {
    const [open, setOpen] = useState(false);
    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    function scrollTo(id: string) {
        const element = document.getElementById(id);
        element?.scrollIntoView();
    }
    const navList = (
        <ul className="mt-2 mb-4 flex flex-col gap-6 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center md:gap-8">
            <button>
                <Typography
                    as="li"
                    variant="h4"
                    className="p-1 font-bold text-gold hover:text-white"
                >
                    <a onClick={() => { scrollTo('page_home') }} className="flex items-center">
                        HOME
                    </a>
                </Typography>
            </button>
            <button>
                <Typography
                    as="li"
                    variant="h4"
                    className="p-1 font-bold text-gold hover:text-white"
                >
                    <a onClick={() => { scrollTo('page_upcomingevent') }} className="flex items-center">
                        UPCOMING EVENT
                    </a>
                </Typography>
            </button>
            <button>
                <Typography
                    as="li"
                    variant="h4"
                    className="p-1 font-bold text-gold hover:text-white"
                >
                    <a onClick={() => { scrollTo('page_promo') }} className="flex items-center">
                        PROMO
                    </a>
                </Typography>
            </button>
            <button>
                <Typography
                    as="li"
                    variant="h4"
                    className="p-1 font-bold text-gold hover:text-white"
                >
                    <a onClick={() => { scrollTo('page_ambience') }} className="flex items-center">
                        AMBIENCE
                    </a>
                </Typography>
            </button>
            <button>
                <Typography
                    as="li"
                    variant="h4"
                    className="p-1 font-bold text-gold hover:text-white"
                >
                    <a onClick={() => { scrollTo('page_contactus') }} className="flex items-center">
                        CONTACT US
                    </a>
                </Typography>
            </button>
        </ul>
    );

    return (
        <React.Fragment>
            <Drawer open={open} onClose={closeDrawer} className="nav-drawer block lg:hidden bg-transparent">
                <div className="mb-2 flex items-center justify-between p-4">
                    <Typography variant="h4">
                        Venn Club & Bar
                    </Typography>
                    <IconButton variant="text" color="white" onClick={closeDrawer}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={4}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </div>
                {navList}
            </Drawer>
            <div id="header_sticky" className="bg-black">
                <Navbar className='flex justify-end px-12 lg:justify-center w-full rounded-none z-10 top-0 bg-transparent py-3 px:8 mx-auto  backdrop-blur-none backdrop-saturate-100 border-none shadow-none'>
                    {/* <img className='-m-3 h-24' src='./venn192.png' /> */}
                    <div className='w-full flex justify-between lg:justify-center items-center'>
                        <div className={` text-white capitalize py-1 px-2 hidden lg:flex`}>{navList}</div>
                        <button onClick={() => { scrollTo('page_home') }}>
                            <img
                                className='w-8 sm:w-12 md:w-16 lg:hidden'
                                src={VennLogo}
                            />
                        </button>
                        <IconButton
                            variant="text"
                            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                            ripple={false}
                            onClick={() => openDrawer()}
                        >
                            {open ? (
                                <svg
                                    xmlns="https://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="https://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </IconButton>
                    </div>
                </Navbar>
            </div>
        </React.Fragment>
    );
}