import React from "react";
import { CarouselStylesType } from "@material-tailwind/react"
import CarouselPrevImg from '@assets/images/UI/caraouselPrev.png';
import CarouselNextImg from '@assets/images/UI/caraouselNext.png';

const carousel: CarouselStylesType = {
  defaultProps: {
    prevArrow: ({ handlePrev }) => (
      <img
        className="!absolute top-2/4 left-0 -translate-x-4"
        onClick={handlePrev}
        src={CarouselPrevImg}
        alt="Previous"
      />
    ),
    nextArrow: ({ handleNext }) => (
      <img
        className="!absolute top-2/4 right-0 translate-x-4"
        onClick={handleNext}
        src={CarouselNextImg}
        alt="Previous"
      />
    ),
  },
  styles: {
    base: {
      slide: {
        width: "w-full",
        height: "h-1/2",
        display: "inline-block",
        flex: "flex-none",
      },
    },
  },
};

export const customTheme = {
  button: {
    defaultProps: {
      variant: "filled",
      size: "md",
      color: "gold",
      fullWidth: false,
      ripple: true,
      className: "",
    },
    valid: {
      variants: ["filled", "outlined", "gradient", "text"],
      sizes: ["sm", "md", "lg"],
      colors: [
        "white",
        "blue-gray",
        "gray",
        "brown",
        "deep-orange",
        "orange",
        "amber",
        "yellow",
        "lime",
        "light-green",
        "green",
        "teal",
        "cyan",
        "light-blue",
        "blue",
        "indigo",
        "deep-purple",
        "purple",
        "pink",
        "red",
        "gold"
      ],
    },
    styles: {
      base: {
        initial: {
          verticalAlign: "align-middle",
          userSelect: "select-none",
          fontFamily: "font-sans",
          fontWeight: "font-bold",
          textAlign: "text-center",
          textTransform: "uppercase",
          transition: "transition-all",
          disabled: "disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none",
        },
        fullWidth: {
          display: "block",
          width: "w-full",
        },
      },
      sizes: {
        sm: {
          fontSize: "text-xs",
          py: "py-2",
          px: "px-4",
          borderRadius: "rounded-lg",
        },
        md: {
          fontSize: "text-xs",
          py: "py-3",
          px: "px-6",
          borderRadius: "rounded-lg",
        },
        lg: {
          fontSize: "text-sm",
          py: "py-3.5",
          px: "px-7",
          borderRadius: "rounded-lg",
        },
      },
      variants: {
        filled: {
          gold: {
            backgroud: "bg-gold",
            color: "text-white",
            shadow: "shadow-md shadow-gold/20",
            hover: "hover:shadow-lg hover:shadow-gold/40",
            focus: "focus:opacity-[0.85] focus:shadow-none",
            active: "active:opacity-[0.85] active:shadow-none",
          },
        },
        gradient: {
          gold: {
            backgroud: "bg-gradient-to-tr from-gold to-gold",
            color: "text-white",
            shadow: "shadow-md shadow-gold/20",
            hover: "hover:shadow-lg hover:shadow-gold/40",
            active: "active:opacity-[0.85]",
          },
        },
        outlined: {
          gold: {
            border: "border border-gold",
            color: "text-gold",
            hover: "hover:opacity-75",
            focus: "focus:ring focus:ring-red-200",
            active: "active:opacity-[0.85]",
          },
        },
        text: {
          gold: {
            color: "text-gold",
            hover: "hover:bg-gold/10",
            active: "active:bg-gold/30",
          },
        },
      },
    },
  },
  carousel: carousel,
  typography: {
    defaultProps: {
      variant: "paragraph",
      color: 'white',
      textGradient: false,
      className: "",
    },
    valid: {
      variants: [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "lead",
        "paragraph",
        "small",
      ],
      colors: [
        "inherit",
        "current",
        "black",
        "white",
        "gold",
        "blue-gray",
        "gray",
        "brown",
        "deep-orange",
        "orange",
        "amber",
        "yellow",
        "lime",
        "light-green",
        "green",
        "teal",
        "cyan",
        "light-blue",
        "blue",
        "indigo",
        "deep-purple",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      variants: {
        h1: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "Acumin",
          fontSize: "text-5xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-tight",
        },
        h2: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "Acumin",
          fontSize: "text-4xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-[1.3]",
        },
        h3: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "Acumin",
          fontSize: "text-3xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-snug",
        },
        h4: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "Acumin",
          fontSize: "text-2xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-snug",
        },
        h5: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "Acumin",
          fontSize: "text-xl",
          fontWeight: "font-semibold",
          lineHeight: "leading-snug",
        },
        h6: {
          display: "block",
          fontSmoothing: "antialiased",
          letterSpacing: "tracking-normal",
          fontFamily: "Acumin",
          fontSize: "text-base",
          fontWeight: "font-semibold",
          lineHeight: "leading-relaxed",
        },
        lead: {
          display: "block",
          fontSmoothing: "antialiased",
          fontFamily: "Acumin",
          fontSize: "text-xl",
          fontWeight: "font-normal",
          lineHeight: "leading-relaxed",
        },
        paragraph: {
          display: "block",
          fontSmoothing: "antialiased",
          fontFamily: "Acumin",
          fontSize: "text-base",
          fontWeight: "font-light",
          lineHeight: "leading-relaxed",
        },
        small: {
          display: "block",
          fontSmoothing: "antialiased",
          fontFamily: "Acumin",
          fontSize: "text-sm",
          fontWeight: "font-light",
          lineHeight: "leading-normal",
        },
      },
      textGradient: {
        bgClip: "bg-clip-text",
        color: "text-transparent",
      },
      colors: {
        inherit: {
          color: "text-inherit",
        },
        current: {
          color: "text-current",
        },
        black: {
          color: "text-black",
        },
        white: {
          color: "text-white",
        },
        gold: {
          color: "text-gold",
        },
        "blue-gray": {
          color: "text-blue-gray-900",
          gradient: "bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400",
        },
        gray: {
          color: "text-gray-700",
          gradient: "bg-gradient-to-tr from-gray-600 to-gray-400",
        },
        brown: {
          color: "text-brown-500",
          gradient: "bg-gradient-to-tr from-brown-600 to-brown-400",
        },
        "deep-orange": {
          color: "text-deep-orange-500",
          gradient: "bg-gradient-to-tr from-deep-orange-600 to-deep-orange-400",
        },
        orange: {
          color: "text-orange-500",
          gradient: "bg-gradient-to-tr from-orange-600 to-orange-400",
        },
        amber: {
          color: "text-amber-500",
          gradient: "bg-gradient-to-tr from-amber-600 to-amber-400",
        },
        yellow: {
          color: "text-yellow-500",
          gradient: "bg-gradient-to-tr from-yellow-600 to-yellow-400",
        },
        lime: {
          color: "text-lime-500",
          gradient: "bg-gradient-to-tr from-lime-600 to-lime-400",
        },
        "light-green": {
          color: "text-light-green-500",
          gradient: "bg-gradient-to-tr from-light-green-600 to-light-green-400",
        },
        green: {
          color: "text-green-500",
          gradient: "bg-gradient-to-tr from-green-600 to-green-400",
        },
        teal: {
          color: "text-teal-500",
          gradient: "bg-gradient-to-tr from-teal-600 to-teal-400",
        },
        cyan: {
          color: "text-cyan-500",
          gradient: "bg-gradient-to-tr from-cyan-600 to-cyan-400",
        },
        "light-blue": {
          color: "text-light-blue-500",
          gradient: "bg-gradient-to-tr from-light-blue-600 to-light-blue-400",
        },
        blue: {
          color: "text-blue-500",
          gradient: "bg-gradient-to-tr from-blue-600 to-blue-400",
        },
        indigo: {
          color: "text-indigo-500",
          gradient: "bg-gradient-to-tr from-indigo-600 to-indigo-400",
        },
        "deep-purple": {
          color: "text-deep-purple-500",
          gradient: "bg-gradient-to-tr from-deep-purple-600 to-deep-purple-400",
        },
        purple: {
          color: "text-purple-500",
          gradient: "bg-gradient-to-tr from-purple-600 to-purple-400",
        },
        pink: {
          color: "text-pink-500",
          gradient: "bg-gradient-to-tr from-pink-600 to-pink-400",
        },
        red: {
          color: "text-red-500",
          gradient: "bg-gradient-to-tr from-red-600 to-red-400",
        },
      },
    },
  },

}

// defaultProps: {
//   prevArrow: ({ loop, handlePrev, firstIndex }) => {
//     return (
//       <button
//         onClick={handlePrev}
//         disabled={!loop && firstIndex}
//         className="!absolute top-2/4 left-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center"
//       >
//         <ChevronLeftIcon strokeWidth={3} className="-ml-1 h-7 w-7" />
//       </button>
//     );
//   },
//   nextArrow: ({ loop, handleNext, lastIndex }) => (
//     <button
//       onClick={handleNext}
//       disabled={!loop && lastIndex}
//       className="!absolute top-2/4 right-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center"
//     >
//       <ChevronRightIcon strokeWidth={3} className="ml-1 h-7 w-7" />
//     </button>
//   ),
//   navigation: ({ setActiveIndex, activeIndex, length }) => (
//     <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
//       {new Array(length).fill("").map((_, i) => (
//         <span
//           key={i}
//           className={`block h-3 w-3 cursor-pointer rounded-full transition-colors content-[''] ${
//             activeIndex === i ? "bg-white" : "bg-white/50"
//           }`}
//           onClick={() => setActiveIndex(i)}
//         />
//       ))}
//     </div>
//   ),
//   autoplay: false,
//   autoplayDelay: 5000,
//   transition: {
//     type: "tween",
//     duration: 0.5,
//   },
//   loop: false,
//   className: "",
// },
// styles: {
//   base: {
//     carousel: {
//       position: "relative",
//       width: "w-full",
//       height: "h-full",
//       overflowX: "overflow-x-hidden",
//       display: "flex",
//     },

//     slide: {
//       width: "w-full",
//       height: "h-full",
//       display: "inline-block",
//       flex: "flex-none",
//     },
//   },
// },